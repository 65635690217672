import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ProfilemenuComponent } from './profilemenu.component';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

@NgModule({
    imports: [CommonModule, IonicModule, FormsModule, RouterModule],
    exports: [
        ProfilemenuComponent
    ],
    declarations: [ProfilemenuComponent],
})
export class ProfilemenuModule { }