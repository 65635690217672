import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Ecommerce_Config } from '../Ecommerce_Config';

@Component({
  selector: 'app-profilemenumobile',
  templateUrl: './profilemenumobile.component.html',
  styleUrls: ['./profilemenumobile.component.scss'],
})
export class ProfilemenumobileComponent implements OnInit {
  selected_menu: string = Ecommerce_Config['current_page'];
  constructor(private route: Router) { }

  ngOnInit() { }

  goToPage(page) {
    this.route.navigate(['/' + page]);
  }

}
